.blog_page {
    min-height: 100vh;
    background: #CACACA;
}

.blog_page .main-header {
    padding: 28px 16px;
    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.04);
    border-radius: 0px 0px 16px 16px;
    margin-bottom: 24px;
}
.blog_page .main-header .header-wrapper{
    padding: 0;
}
.blog_page .main-header .header-wrapper .titel{
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    color: #333333;
    margin-bottom: 6px;
}
.blog_page .main-header .header-wrapper .description{
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #4F4F4F;
}
.blog_page .blog_container .blog-in-progress{
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: none;
    border-radius: 0;
    text-align: left;
    gap: 24px;
    display: flex;
    min-height: 80vh;
}
.blog_page .blog_container .blog-in-progress .col-4{
    width: 280px;
}
.blog_page .blog_container .blog-in-progress .side_bar{
    padding: 16px;
    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
}
.blog_page .blog_container .blog-in-progress .side_bar .title{
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    margin-bottom: 16px;
    display: block;
}
.blog_page .blog_container .blog-in-progress .side_bar .menu_items{
    padding: 16px 0 16px 16px;
    background: #F7F7F7;
    border-radius: 12px;
    list-style: none;
    margin: 0;
}
.blog_page .blog_container .blog-in-progress .side_bar .menu_items .title{
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #0071BD;
    cursor: default;

}
.blog_page .blog_container .blog-in-progress .side_bar .menu_items .menu_link{
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #828282;
    padding: 8px 0 8px 15px;
    display: block;
    text-decoration: none;
    position: relative;
    cursor: default;
}


.blog_page .blog_container .blog-in-progress .side_bar .menu_items .menu_link:hover{
    color: #0071BD;
}

.blog_page .blog_container .blog-in-progress .side_bar .menu_items .menu_link.active{
    background: white;
    color: #333333;
    border-left: 2px solid #0071BD;
}

.blog_page .blog_container .blog-in-progress .side_bar .menu_items hr{
    margin: 24px 0;
    width: calc(100% - 15px);
}
.blog_page .blog_container .blog-in-progress .side_bar,
.blog_page .blog_container .blog-in-progress .right_section{
    height: 100%;
}
.blog_page .blog_container .blog-in-progress .right_section{
    padding: 16px;
    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    gap: 16px;
    display: grid;
    grid-template-columns: 305px auto auto;
}
.blog_page .blog_container .blog-in-progress .right_section .card{
    width: 305px;
    height: 328px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}
.blog_page .blog_container .blog-in-progress .right_section .card .card_conent{
    padding: 16px;
    background: #FFFFFF;
    border-radius: 10px;
    position: absolute;
    bottom: 10px;
    left: 10px;
    width: calc(100% - 20px);
}
.blog_page .blog_container .blog-in-progress .right_section .card .card_conent .title{
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 4px;
    color: #333333;
    cursor: pointer;
}
.blog_page .blog_container .blog-in-progress .right_section .card .card_conent .description{
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #4F4F4F;
}

@media (max-width: 1400px) {
    .blog_page .blog_container .blog-in-progress .right_section{
        grid-template-columns: 49% 49%;
    }
    .blog_page .blog_container .blog-in-progress .right_section .card{
        width: 100%;
    }
    .blog_page .blog_container .blog-in-progress .right_section .card img{
        height: 100%;
    }
}

@media (max-width: 992px) {
    .blog_page .container{
        max-width: 100%;
    }
}
@media (max-width: 768px) {
    .blog_page .blog_container .blog-in-progress .right_section{
        grid-template-columns: 100%;
    }
}
@media (max-width: 576px) {
    .blog_page{
        padding-bottom: 50px;
    }
    .blog-in-progress{
        flex-wrap: wrap;
    }
    .blog-in-progress .col-4,
    .blog-in-progress .col-12{
        width: 100% !important;
    }
}