.NewLandingPage .main-header .container {
    padding: 0px;
    margin: 50px 120px;
}

.NewLandingPage .logo-img img {
    max-width: 206px !important;
}
.section1_main_div{
    padding-top: 114px;
}

.NewLandingPage .header-buttons {
    display: none !important;
}

.slider-bottom-button {
    padding: 0;
    height: 10px !important;
    width: 10px !important;
    border-radius: 50%;
    border: none;
    background: #C7CAD4;
}

.carousel-indicators .active {
    background: #646670;
    width: 15px !important;
    height: 15px !important;
}

.carousel-item img {
    /* height: 100%; */
    height: 80% !important;
    width: 100%;
    object-fit: cover;
}

#carouselExampleIndicators .carousel-indicators {
    gap: 3px;
    display: flex;
    justify-content: start;
    align-items: center;
}

.Landing_main_div {
    /* margin-top: 237px; */
    margin-top: 150px;
    margin-left: 120px;
}

.Landing_main_div .main_header p {
    color: #25282F;
    font-size: 40px;
    font-weight: 300;
    line-height: 150%;
    margin: 0;
    font-style: normal;
}

.Landing_main_div .main_header p span {
    color: #E4AE40;
}

.Landing_main_div .main_paragraf p {
    color: #646670;
    /* font-size: 24px; */
    font-size: 18px;
    font-style: normal;
    font-weight: 200;
    /* line-height: 150%; */
    line-height: 140%;
    margin: 0;
}

.Landing_main_div .main_paragraf {
    margin-bottom: 10px;
}

.Landing_main_div .btn_login {
    padding: 12px 24px;
    color: #4a88b7;
    border: 1px solid #80B2D8 !important;
    background: transparent;

    /* Body bold 16 */
    border-radius: 16px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.Landing_main_div .btn_sign_up {
    padding: 12px 40px;
    color: white;
    border: none;
    background: #1570B7;

    /* Body bold 16 */
    border-radius: 16px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.Landing_main_div .btn_group {
    display: flex;
    gap: 15px;
    margin-top: 15px;
}

.section1_header {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-bottom: 40px;
}

.section1_header div {
    background: #E4AE40;
    height: 43px;
    width: 43px;
}

.section1_header p {
    color: #25282F;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.section1_paragraf p {
    color: #646670;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    /* padding-right: 350px; */
    padding-bottom: 45px;
}

.Fine-tuning-p {
    color: #25282F;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    margin-bottom: 30px !important;
    font-weight: 700;
    text-align: start;
    line-height: normal;
    position: relative;
}

.Fine-tuning-p::before {
    position: absolute;
    content: "";
    height: 3px;
    width: 30px;
    bottom: -3px;
    left: 0;
    background-color: #E4AE40;
}

.Refin-models-p {
    color: #646670;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 40px;
}

.Fine-tuning-img {
    border-radius: 16px;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
    width: 100%;
    object-fit: cover;
}

.tab-bar-div .nav-pills {
    background-color: #25282F;
    height: 450px;
    position: relative;
    width: 370px;
    border-radius: 16px;
    padding-top: 30px;
}

.postion-change-div button {
    width: 370px;
    height: 64px;
    display: flex !important;
    align-items: flex-start !important;
    padding: 16px 44px 16px 20px !important;
    margin-left: 30px;
    color: #FFF !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
}

.postion-change-div .active {
    border-radius: 16px !important;
    background-color: #E4AE40 !important;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.30);
}

.section2_header {
    display: flex;
    gap: 10px;
    margin-bottom: 40px;
    /* margin-top: 50px; */
    margin-top: 80px;
    align-items: center;
}

.section2_header div {
    width: 43px;
    height: 43px;
    background: #E4AE40;
}

.section2_header p {
    color: #25282F;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.all-models-main {
    padding: 60px 100px;
}

.allmodel-hed {
    color: #25282F;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    position: relative;
    margin-bottom: 55px;
}

.allmodel-hed::before {
    position: absolute;
    content: "";
    height: 3px;
    width: 30px;
    bottom: -5px;
    left: 0;
    background-color: #E4AE40;
}

.allmodel-img {
    width: 366px;
    height: 75px;
    margin-bottom: 20px;
}

.allmodels-p {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.section3_main {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 40px;
}

.section3_main div {
    width: 43px;
    height: 43px;
    background: #E4AE40;
}

.section3_main p {
    color: #25282F;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.section3_prent .steps .stepItem {
    display: flex;
    align-items: center;
    margin-left: 20px;

}

.section3_prent .steps .stepItem .circle {
    margin-right: 17px;
}

.section3_prent .steps .stepItem {
    /* padding-bottom: 107px; */
    padding-bottom: 80px;
    position: relative;
    z-index: 1;
}

.section3_prent .steps .stepItem::after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 15px;
    width: 5px;
    height: 100%;
    background: #30AC63;
    z-index: -1;
}

.section3_prent .steps .stepItem .stepTitle {
    color: #25282F;
    font-size: 18px;
    line-height: normal;
    font-weight: 700;
}

.section3_prent .steps .stepItem .stepTitle span {
    color: #898989;
    font-size: 14px;
    font-weight: 400;
}

.section3_prent .steps .stepItem:last-child {
    padding-bottom: 0;
}

.section3_prent .steps .stepItem:last-child:after {
    display: none;
}

.Title p {
    color: #25282F;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    position: relative;
}

.Title p::before {
    position: absolute;
    content: "";
    height: 3px;
    width: 30px;
    bottom: -4px;
    left: 0;
    background-color: #E4AE40;
}

.Title span {
    display: block;
    color: #646670;
    margin-top: 20px;
    margin-bottom: 40px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.check_video_btn {
    margin-top: 44px;
}

.check_video_btn button {
    color: #FFF;
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 16px;
    background: #1570B7;
    display: inline-flex;
    padding: 12px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: none;
}

.performance-val {
    height: 37px !important;
}

.section4_main {
    background: linear-gradient(90deg, #0071BD 1.84%, #80B2D8 102.67%);
    flex-shrink: 0;
    margin-top: 100px;
}

.section4_main div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 50px 120px;
}
.section5_main {
    margin-top: 83px;
}
.section6_main{
    margin-top: 70px;
}

.section4_main div p {
    color: #FFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-right: 60px;
}

.section4_main div button {
    display: flex;
    padding: 12px 24px;
    flex-direction: column;
    width: 350px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 16px;
    background: #1570B7;
    color: #FFF;
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: none;
}

.section5_hed {
    display: flex;
    padding-left: 12px;
    gap: 10px;
    margin-bottom: 80px;
    align-items: center;
}

.section6_had {
    display: flex;
    gap: 10px;
    padding-left: 12px;
}

.section7_had {
    display: flex;
    gap: 10px;
    align-items: center;
}

.section5_hed div,
.section6_had div,
.section7_had div {
    background: #E4AE40;
    height: 43px;
    width: 43px;

}

.section5_hed p,
.section6_had p,
.section7_had p {
    color: #25282F;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0px;
}

.section5_main .row .col-lg-4 p {
    color: #25282F;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    position: relative;

}

.section5_main .row .col-lg-4 p::before {
    position: absolute;
    content: "";
    height: 3px;
    width: 30px;
    bottom: -3px;
    left: 0;
    background-color: #E4AE40;
}

.section5_main .row .col-lg-4 span {
    display: inline-block;
    color: #25282F;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.section6_main .item h2 {
    position: absolute;
    left: 30px;
    bottom: 30px;
    color: white;
    font-size: 24px;
    padding-bottom: 5px;
}

.section6_main .item h2:after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    width: 38px;
    height: 3px;
    background: linear-gradient(0deg, #E4AE40 0%, #E4AE40 100%), #FFF;
}

.tab-content{
    /* width: 480px; */
    width: 700px;
}

.section6_main .col-6 {
    border-radius: 16px !important;
}

.section6_main img {
    width: 100%;
    margin-top: 16px;
    border-radius: 16px;
    height: 400px;
    object-fit: cover;
}

.section6_had {
    margin-bottom: 40px;
}

.accordion-body {
    color: #222 !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 32px !important;
}

.section7_had {
    margin-bottom: 56px;
}
.section7_main{
    margin-top: 102px;
}
.footer-top {
    margin: 50px 120px;
}

.footer-bottom {
    text-align: center !important;
}

.footer-helpcenter h2,
.footer-location h2 {
    color: #94A3B8;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 1px;
    text-transform: uppercase;
}
.footer-wrapper{
    background: #F6F7FA;
    padding-top: 10px;
    margin-top: 100px;
}
.footer-about{
    background-color: #F6F7FA !important;
}
.footer-bottom p{
    margin-bottom: 0;
}

.footer-helpcenter h2 {
    padding-bottom: 16px;
}

.three-svgs {
    display: flex;
    gap: 13px;
    margin-top: 25px;
    align-items: center;
}

.sec3-2{
    margin-top: 200px;
}

.sec3-1{
    /* margin-top: 200px; */
    margin-top: 150px;
}

.footer-location p a {
    color: #0070E0;
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
    padding-top: 24px;
    display: inline-block;
}

.footer-location p {
    margin: 0;
}

.footer-help-center-head p {
    color: #090914;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
}


@media (max-width: 1140px) {
    .Landing_main_div {
        margin-top: 130px;
    }
    .tab-bar-div .nav-pills{
        width: 350px;
        height: 410px;
    }
    .postion-change-div button{
        width: 350px;
    }
    .all-models-main{
        padding: 60px 80px;
    }
    .allmodel-hed{
        margin-bottom: 40px;
    }

    .Landing_main_div .main_header p {
        font-size: 25px;
    }

    .Landing_main_div .main_paragraf p {
        font-size: 14px;
    }

    .Landing_main_div .btn_sign_up {
        padding: 10px 28px;
    }

    .Landing_main_div .btn_login {
        padding: 10px 18px;
    }

    .section1_paragraf p {
        padding-right: 200px;
        padding-bottom: 20px;
    }

    .section1_header{
        padding-bottom: 20px;
    }
    .section6_main .item h2{
        font-size: 18px;
    }
    .section3_prent .steps .stepItem{
        padding-bottom: 50px;
    }
    .circle svg{
        width: 25px;
    }
    .section3_prent .steps .stepItem::after {
    left: 10px;
    bottom: -7px;
    }
    .Title p{
        font-size: 20px;
    }
}

@media (max-width: 992px) {
    .Landing_main_div .main_header p {
        font-size: 18px;
    }
    .Title img{
        height: 500px;
    }
    .section3_main{
        margin-bottom: 20px;
    }
    .sec3-1{
        margin-top: 100px;
    }
    .sec3-2{
        margin-top: 100px;
    }
    .section4_main{
        margin-top: 50px;
    }
    .section4_main div p{
        font-size: 22px;
    }
    .section4_main div button{
        width: auto;
        font-size: 13px;
    }
    .section5_hed{
        margin-bottom: 30px;
    }
    .Customize_class{
        margin: 0 !important;
    }
    .section6_main .item h2{
        font-size: 25px;
    }
    .footer-wrapper{
        margin-top: 55px;
    }
    .tab-content{
        width: 350px;
    }
    .allmodel-hed{
        margin-bottom: 20px;
    }
    .allmodels-p{
        font-size: 14px;
    }
    .section1_header p{
        font-size: 25px;
        margin: 0;
    }
        .tab-bar-div .nav-pills{
        width: 330px;
    }
    .postion-change-div button{
        width: 330px;
    }
    .all-models-main{
        padding: 60px 60px;
    }
    .postion-change-div button{
        font-size: 16px;
    }
    .section1_paragraf p {
        padding-right: 150px;
        padding-bottom: 15px;
        font-size: 15px;
    }
    .section1_header{
        padding-bottom: 15px;
    }

    .Landing_main_div .main_paragraf p {
        font-size: 12px;
    }

    .Landing_main_div .btn_login {
        font-size: 12px;
    }
    .Refin-models-p{
        font-size: 14px;
        padding-bottom: 20px;
        height: 100px;
    }
    .Fine-tuning-p{
        margin-bottom: 20px;
        font-size: 22px;
    }
    .allmodel-img{
        width: 300px;
        height: auto;
    }
    .section7_had{
        margin-bottom: 33px;
    }
    .footer-bottom{
        margin: 0px 120px;
    }
    .three-svgs{
        display: none;
    }
}

@media (max-width: 768px) {
    .carousel-inner {
        padding-left: 120px;
    }
    .all-models-main{
        padding: 0;
        padding-top: 35px;
    }
    .tab-bar-div .nav-pills{
        width: 100% !important;
    }
    .postion-change-div button{
        width: 100% !important;
    }
    .sec3-1{
        margin-top: 55px;
    }
    .sec3-2{
        margin-top: 55px;
    }
    .section7_main{
        margin-top: 60px;
    }
    .section6_had{
        margin-bottom: 20px;
    }
    .footer-bottom{
        margin: 0px 120px;
    }
    .postion-change-div button {
        font-size: 15px;
    }
    .tab-bar-div .nav-pills{
        height: 390px;
        width: 300px;
    }
    .postion-change-div button{
        width: 300px;
    }

    .section1_paragraf p {
        padding-right: 40px;
        font-size: 13px;
    }
    .section1_main_div{
        padding-top: 50px;
    }
    .Fine-tuning-p{
        margin-bottom: 15px !important;
        padding-top: 20px;
    }
    .Refin-models-p{
        padding-bottom: 10px;
        height: auto;
    }
    .Landing_main_div {
        margin-bottom: 60px;
    }

    .Landing_main_div .main_header p {
        font-size: 25px;
    }

    .Landing_main_div .main_paragraf p {
        font-size: 15px;
        padding-right: 100px;
    }

    .Landing_main_div .btn_login {
        font-size: 14px;
    }

    #carouselExampleIndicators .carousel-indicators {
        justify-content: center;
    }
    .NewLandingPage .main-header .container{
        padding: 0px;
    margin: 50px 120px;
    margin-left: 0;
    padding-left: 25px;
    

    }
    .Landing_main_div{
        margin-top: 100px;
        margin-left:0px ;
        padding-left: 25px;
    }
    .container{
        width: 100%; 
    }
    .section7_had p{
        font-size: 23px;
        margin-bottom: 0;
    }
    .section5_hed{
        margin-bottom: 10px;
    }
    .section5_main .row .col-lg-4 span{
        padding-right: 150px;
    }
    .section4_main div p{
        font-size: 20px;
    }
    .Industry-all-images img{
        height: 350px;
    }
}

@media (max-width: 576px) {
    .Landing_main_div .main_header p {
        font-size: 19px;
    }

    .Landing_main_div .main_paragraf p {
        font-size: 12px;
    }

    .Landing_main_div .btn_login {
        font-size: 12px;
    }
    .Industry-all-images img{
        height: 250px;
    }
    .section2_header p ,.section3_main p{
        font-size: 24px;
    }
    .section4_main div p{
        font-size: 15px;
    }
    .section5_hed p{
        font-size: 21px;
    }
    .footer-bottom{
        font-size: 12px;
    }
    .footer-top{
        margin: 20px 120px;
    }
}