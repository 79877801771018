.gridsCards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    padding: 0 10px;
  }
.card-one-classification {
    /* border: 1px solid #ccc; */
    /* box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3); */
    /* width: 20rem; */
    /* height: 20rem; */
    /* width: 20rem;
    height: 20rem; */
    background: #F7F7F7;
    border-radius: 16px;
    padding: 18px;
    width: 100%;
  }

  .completion-user-selected{
    border: 1px solid #0270BD;
  }

  .edit-answer{
    background: rgba(0, 113, 189, 0.06);
  }
  .card-one-classification .card-body{
    padding: 0;
  }
  .card-one-classification .card-body .avatar .avatar-img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #dfdfdf;
  }
  .card-one-classification .card-body .description{
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #4F4F4F;
    margin-bottom: 18px;
  }
  .card-one-classification .card-body .btn-groups,
  .gridsCard-block .card-body .community-btn-groups {
    display: flex;
    align-items: center;
    width: 100% !important;
  }
  .card-one-classification .card-body .community-btn-groups {
      /* added new */ 
      max-width: 100%!important;
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
    }
    .card-one-classification .edit_btn{
        margin-top: 20px;
    }
  .card-one-classification .card-body .btn-group .btn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 10px;
    background: rgba(224, 224, 224, 0.3);
    border-radius: 50px;
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    color: #333333;
  }
  /* .card-one-classification .card-body .community-btn-groups .btn{
    width: 126px;
  } */
  .questionAnswerCards{
    grid-template-columns: 238px 238px 238px;
    display: grid;
    width: 100%;
    gap: 16px;
  }

  .question{
    margin-bottom: 16px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 96px;
  }
  .question span{
    font-weight: 300;
    font-size: 12px;
    /* line-height: 24px; */
    color: #4F4F4F;
  }


.save-edit-card-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    background-color: #1E7AC1;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
  }
  
  .save-edit-card-button:hover {
    background-color: #146194;
  }
  
  .save-edit-card-button:active {
    background-color: #0D3C5F;
  }
  
  .cancel-edit-card-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    background-color: #ffffff;
    color: #d44a4a;;
    border: 1px solid #d44a4a;
    border-radius: 4px;
    cursor: pointer;
    font-size: 11px;
  }
  
  .cancel-edit-card-button:hover {
    background-color: #F2F2F2;
  }
  
  .cancel-edit-card-button:active {
    background-color: #E5E5E5;
  }
  .more{
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-decoration-line: underline;
    color: #2F80ED;
    margin-top: 16px;
  }