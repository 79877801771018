.agent-background {
    background-image: linear-gradient(10deg, #0e0e10, #1a1a1d, #252529, #303036);
}

.agent-header {
    color: #FFFFFF;
    padding: 2rem 1rem; /* Reduced horizontal padding */
    text-align: center;
    height: auto; /* Changed from fixed height */
    font-family: 'Helvetica Neue', sans-serif;
}

.agent-header h1 {
    font-size: 2.5rem; /* Reduced font size */
    margin-top: 1rem; /* Reduced margin */
    font-weight: 450;
}

.agent-header p {
    max-width: 90%; /* Increased for smaller screens */
    margin-left: auto;
    margin-right: auto;
    font-size: 1rem; /* Reduced font size */
    color: #CCCCCC; 
    font-family: 'Helvetica Neue', sans-serif;
}

.agent-header-buttons {
    margin-top: 1rem;
}

/* Primary button with teal outline */
.agent-header .button-primary {
    
    border: 2px solid #0087ca; /* Teal border */
    
    padding: 0.5rem 1rem;
    margin-right: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: 500;
    background-color: #0087ca; /* Teal background on hover */
    color: #ffffff;
}

.agent-header .button-primary:hover {
    background-color: transparent; /* No fill color */
    color: #0087ca; /* Teal text */
}

/* Secondary button with subtle gray fill */
.agent-header .button-secondary {
    background-color: #6E6E6E; /* Subtle gray */
    border: none;
    color: white;
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.agent-header .button-secondary:hover {
    background-color: #8C8C8C; /* Lighter gray on hover */
}


.agent-chart-placeholder {
    text-align: center;
    line-height: 200px;
    color: #CCCCCC;
}

.iframeVideoVimeo {
    box-shadow: rgba(212, 182, 182, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 100%; /* Responsive width */
    height: auto; /* Responsive height */
}

.use-cases-section {
    padding: 2rem 1rem; /* Reduced horizontal padding */
    text-align: center; 
    background-image: linear-gradient(10deg, #0e0e10, #1f1f21, #2f2f31, #3f3f41);
    color: #FFFFFF;
}

.use-cases-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    max-width: 1200px; 
    margin: 0 auto; 
}

.use-case::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('../assets/svgs/usecase.jpg'); 
    background-size: cover; 
    opacity: 0.5; 
    z-index: -1;
    height: 5.6rem;
}

.use-case {
    width: 100%; /* Full width for smaller screens */
    text-align: center;
    padding: 1rem;
    background-color: rgba(255, 255, 255, 0.05); 
    border-radius: 8px;
    backdrop-filter: blur(5px); 
    color: #CCCCCC; 
    position: relative;
    overflow: hidden;
}



@media (min-width: 768px) {
    .use-case {
        width: 48%; /* Adjust for medium screens */
    }
}

@media (min-width: 992px) {
    .agent-header h1 {
        font-size: 3rem; /* Larger font size for larger screens */
    }
    .agent-header p {
        max-width: 75%; /* Original max-width */
        font-size: 1.2rem; /* Larger font size */
    }

    .use-case {
        width: 30%; /* Original width for larger screens */
    }
}

.modalClass {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent; /* Ensuring modal background is transparent */
    z-index: 100;
    outline: transparent;
}

.darkOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7); /* Dark background */
    z-index: 50; /* Ensure it's above other content */
}

.contentContainer {
    background-color: white; /* White background for the content area */
    padding: 20px; /* Add some padding around the content */
    border-radius: 5px; /* Optional: for rounded corners */
    max-width: 95%; /* Maximum width of the content container */
    max-height: 95%; /* Maximum height of the content container */
    margin: auto; /* Centers the container in the modal */
}




.announcement {
    text-decoration: underline;
    text-underline-offset: 0.3em;
    font-weight: 500; 
    font-size: 1em; 
    /* color: #0c5ed7; */
    color: #0c5ed7;
    padding: 0.1em; 
    border-radius: 5px; 
    margin-top: 1em;
}
