.new_landing{
    padding-top: 120px;
  }
  .new_landing .container{
    max-width: 1200px;
  }
  .new_landing .banner{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 200px;
    padding: 0 16px;
  }
  .new_landing .banner .left_section,
  .new_landing .banner .right_section{
    width: 50%;
  }
  .new_landing .banner .left_section .title{
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 65px;
    color: #000000;
    margin-bottom: 10px;
  }
  .new_landing .banner .left_section .desription{
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #4F4F4F;
    width: 90%
  }
  .new_landing .banner .right_section .banner_img{
    position: relative;
  }
  /*
  .new_landing .banner .right_section .banner_img::after{
    position: absolute;
    content: "";
    bottom: -65px;
    left: -65px;
    background: url('../assets/svgs/Vector.png') no-repeat;
    width: 140px;
    height: 140px;
    z-index: -1;
  }
  */
  
  .card_section{
    display: flex;
    align-items: start;
    gap: 24px;
    margin-bottom: 160px;
  }
  .questionAnswerCards{
    margin-bottom: 16px;
  }
  .card_section .more_opstion span{
    cursor: pointer;
  }
  .card_section .data_section{
    padding: 24px;
    background: #EBF4FA;
    border-radius: 16px;
    text-align: center;
  }
  .card_section .data_section .title{
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    color: #000000;
    margin-bottom: 10px;
  }
  .card_section .data_section .description{
    font-weight: 410;
    font-size: 14px;
    line-height: 25px;
    color: #4F4F4F;
    margin-bottom: 24px;
    text-align: justify;
  }
  .card_section .data_section .download_btn{
    background: #0071BD;
    border-radius: 10px;
    padding: 12px 16px;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #FFFFFF;
    border: none;
    margin: auto;
  }
  .card_section .data_section .download_btn img{
    margin-right: 6px;
  }
  
  .detection_task{
    padding: 52px 29px;
    background: #0071BD;
    border-radius: 24px;
    margin-bottom: 50px;
  }
  .detection_task .title{
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 6px;
  }
  .detection_task .description{
    font-weight: 200;
    /* font-size: 12px; */
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 2px;
  }
  .detection_task .task_data{
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    gap: 32px;
  }
  .detection_task .task_data li{
    color: #FFFFFF;
    position: relative;
  }
  .detection_task .task_data li::after{
    position: absolute;
    content: '';
    top: 1px;
    right: -16px;
    width: 1px;
    height: 90%;
    background: white;
  }
  .detection_task .task_data li:last-child::after{
    display: none;
  }
  
  .fineTunedModel{
    background: #FFFFFF;
    border-radius: 16px;
    padding: 16px;
    margin-top: 52px;
    position: relative;
    margin-bottom: 40px;
    z-index: 0;
  }
  .fineTunedModel::after,
  .fineTunedModel::before{
    position: absolute;
    content: "";
    left: 50%;
    bottom: -20px;
    width: 90%;
    height: 100px;
    background: #FFFFFF;
    opacity: 0.2;
    border-radius: 14px;
    transform: translateX(-50%);
    margin: auto;
    z-index: -1;
  }
  .fineTunedModel::before{
    bottom: -40px;
    width: 85%;
  }
  .detection_task .tabs .nav .nav-link{
    background: none;
    color: #333333;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    border-bottom: 1px solid #E0E0E0;
    border-radius: 0;
  }
  .detection_task .tabs .nav .active{
    font-weight: 600;
    color: #0071BD;
    border-color: #0071BD;
  }
  
  .detection_task .tab-content .table-responsive{
    max-height: 350px;
    overflow-y: auto;
  }
  
  .detection_task .tab-content .table-responsive::-webkit-scrollbar-track{
      -webkit-box-shadow: inset 0 0 6px #F0F0F0;
      border-radius: 10px;
      background-color: #F5F5F5;
  }
  
  .detection_task .tab-content .table-responsive::-webkit-scrollbar{
  }
  
  .detection_task .tab-content .table-responsive::-webkit-scrollbar-thumb{
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px  #BDBDBD;
      background-color: #555;
  }
  .detection_task .tab-content .table{
    border-spacing: 0 10px;
    border-collapse: separate;
    font-size: 12px;
  }
  .detection_task .tab-content .table thead tr th{
    background: #F7F7F7;
    /* border: none; */
    color: #808B97;
    white-space: nowrap;
  }
  .detection_task .tab-content .table thead tr th:first-child{
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .detection_task .tab-content .table thead tr th:last-child{
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .detection_task .tab-content .table tbody tr td{
    font-size: 12px;
    color: #0B114F;
    /* border-top: none; */
    font-size: 12px;
  }
  .detection_task .tab-content .table tbody tr th{
    /* border-top: none; */
  }
  .detection_task .tab-content .table tbody tr td,
  .detection_task .tab-content .table tbody tr th{
    border-bottom: 1px solid #E0E0E0;  
  }
  .inference .Classification-computation-content{
    display: flex;
    margin-bottom: 24px;
    gap: 38px;
  }
  .inference .Classification-computation-content .Classification-computation-time,
  .inference .Classification-computation-content .Computation-time{
    position: relative;
  }
  .inference .Classification-computation-content .Classification-computation-time .Classification .title{
    margin: 0;
  }
  .inference .Classification-computation-content .Classification-computation-time::after,
  .inference .Classification-computation-content .Computation-time::after{
    position: absolute;
    content: "";
    top: 0;
    right: -19px;
    width: 1px;
    height: 100%;
    background: #E0E0E0;
  }
  .inference .Classification-computation-content .Computation-time:last-child:after{
    display: none;
  }
  .inference .Classification-computation-content .Classification-computation-time{
    width: 40%;
  }
  .inference .Classification-computation-content .Computation-time{
    width: 60%;
  }
  .inference .Classification-computation-content .Computation-time{
    background: linear-gradient(0deg, rgba(0, 113, 189, 0.08), rgba(0, 113, 189, 0.08)), rgba(255, 255, 255, 0.2);
    border-radius: 12px;
    padding: 20px;
  }
  .inference .Classification-computation-time .title{
    margin-bottom: 24px;
    display: flex;
    align-items: center;
  }
  .inference .Classification-computation-time .Classification{
    margin-bottom: 10px;
  }
  .inference .title{
    display: flex;
    align-items: center;
  }
  .inference .title svg{
    margin-left: 16px;
  }
  
  .inference .Classification-computation-time .Classification{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .inference .Classification-computation-time .Classification .title{
    color: #333333;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px
  }
  
  .inference .dropdown button{
    background: #E0E0E0;
    border-color: #E0E0E0;
    padding: 6px 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #333333;
    border: none;
    border-radius: 6px;
  }
  
  .inference .increasing{
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    padding: 14px 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    /* margin-bottom: 20px; */
    width: 100%;
  }
  .inference .compute_btn{
    border-radius: 8px;
    background: #0071BD;
    padding: 10px 18px !important;
    padding: 12px 20px;
    font-weight: 500;
    border: none;
    color: white;
    font-size: 14px!important;
    line-height: 18px;
  }
  .Computation-time .title{
    margin-bottom: 24px;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    color: #333333;
    margin-bottom: 6px;
  }
  .Computation-time .progress-bar{
    background-color: transparent;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .Computation-time .progress-bar:not(:last-child){
    margin-bottom: 28px;
  }
  .Computation-time .progress-bar .progress{
    background: #EAEEF6;
    border-radius: 3px;
    height: 6px;
    width: 100%;
  }
  .Computation-time .progress-bar .progress .bar,
  .Computation-time .progress-bar2 .progress .bar,
  .Computation-time .progress-bar3 .progress .bar{
    border-radius: 3px;
  }
  .Computation-time .progress-bar .progress .bar{
    width: 30%;
    background: #EB5757;
  }
  .Computation-time .progress-bar2 .progress .bar{
    background: #0071BD;
    width: 50%;
  }
  .Computation-time .progress-bar3 .progress .bar{
    background: #27AE60;
    width: 10%;
  }
  .Computation-time .progress-bar .label{
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin-right: 16px;
    color: #4F4F4F;
    min-width: 80px;
    text-align: start;
  }
  .Computation-time .progress-bar .progress-number{
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #171725;
    margin-left: 16px;
    min-width: 36px;
    text-align: start;
  }
  
  
  @media (max-width: 1200px) {
    .card_section .questionAnswerCards{
      grid-template-columns: 50% 50%;
      width: 100%;
    }
  }
  @media (max-width: 1100px) {
    .fineTunedModel .recharts-wrapper,
    .recharts-legend-wrapper{
      width: 800px !important;
    }
    .fineTunedModel .recharts-wrapper svg{
      width: 100%;
    }
  }
  @media (max-width: 992px) {
    .new_landing{
      padding-top: 80px !important;
    }
    .banner{
      flex-wrap: wrap;
      margin-bottom: 80px !important;
    }
    .banner .left_section,
    .banner .right_section{
      width: 100% !important;
    }
    .banner .right_section .banner_img{
      display: flex;
      justify-content: center;
    }
    .banner .right_section img{
      width: 40%;
      margin-top: 20px;
    }
    .new_landing .banner .right_section .banner_img::after{
      left: -5px;
    }
  }
  @media (max-width: 920px) {
    .fineTunedModel .recharts-wrapper,
    .recharts-legend-wrapper{
      width: 100% !important;
    }
  }
  @media (max-width: 768px) {
    .new_landing{
      padding-top: 50px !important;
    }
    .card_section{
      flex-wrap: wrap;
      margin-bottom: 50px;
      flex-direction: column-reverse;
    }
    .card_section .questionAnswerCards{
      grid-template-columns: 100%;
      width: 100%;
    }
    .detection_task .task_data{
      row-gap: 10px;
      flex-wrap: wrap;
    }
    .inference .Classification-computation-content{
      flex-wrap: wrap;
    }
    .inference .Classification-computation-content .Classification-computation-time,
    .inference .Classification-computation-content .Computation-time{
      width: 100%;
    }
    .inference .Classification-computation-content .Classification-computation-time::after{
      display: none;
    }
  }

  @media (max-width: 576px) {
    .new_landing{
      padding-top: 20px !important;
    }
    .new_landing .banner .left_section .title{
      font-size: 40px;
      line-height: 45px;
    }
    .banner{
      margin-bottom: 50px !important;
    }
    .banner .right_section img{
      width: 70%;
    }
  }